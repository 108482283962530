import React from "react"


const FeatureBox = ({ title, description, img, wowDuration, wowDelay, moreInfo = true }) => {
  return (

    <div className="col-lg-4 col-md-12 tw-mb-30 wow fadeInUp" data-wow-duration={wowDuration} data-wow-delay={wowDelay}>
      <div className="features-box">
        <div className="features-icon d-table">
          <div className="features-icon-inner d-table-cell">
            <img src={img} alt=""/>
          </div>
        </div>
        <h3>{title}</h3>
        <p>{description}</p>

        {moreInfo &&
        <a href="#" className="tw-readmore">
          Conoce más
          <i className="fa fa-angle-right"></i>
        </a>
        }
      </div>
    </div>
  )
}


export default function FeaturesArea() {
  return (
    <section id="tw-features" className="tw-features-area">
      <div className="container">
        <div className="row tw-mb-65">
          <div className="col-md-4 wow fadeInLeft" data-wow-duration="1s">
            <h2 className="column-title text-md-right text-sm-center">Ingresa a la era digital</h2>
          </div>
          <div className="col-md-7 ml-md-auto wow fadeInRight" data-wow-duration="1s">
            <p className="features-text">
              En el contexto actual, los negocios se estan transformado mediante el uso de la tecnología.
              Digitaliza todos tus procesos para poder tener visibilidad total de tu sala y poder gestionarla
              desde cualquier parte usando tu dispositivo movil. </p>
          </div>
        </div>
        <div className="row">


          {/*<FeatureBox title={"Casino onlinee"}*/}
          {/*            description={"Plataforma segura, escalable y personalizable con acceso a uno de los mejores contenidos de juego\n" +*/}
          {/*            "          en linea. Integración a métodos de pago, gestión de jugadores, marketing, y más..."}*/}
          {/*            img={"images/icon/feature1.png"}*/}
          {/*            wowDuration={"1s"}*/}
          {/*            wowDelay={".2s"}/>*/}


          <FeatureBox title={"Cupones"}
                      description={"Nuestro sistema estrella. Genera e imprime los cupones de tus clientes de manera automatica. Evita la manipulación de cupones físicos y cumple el " +
                      "protocolo mientras brindas una mayor seguridad a tus clientes."}
                      img={"images/icon/feature4.png"}
                      wowDuration={"1.9s"}
                      wowDelay={".6s"}
                      moreInfo={false}
          />

          <FeatureBox title={"Promociones"}
                      description={"Gestiona la promocion del mes de manera digital, configura multiplicadores de puntos y cupones, sorteos, etc. Imprime tus formatos de " +
                      "manera digital. Brinda una mayor transparencia a tus clientes"}
                      img={"images/icon/feature4.png"}
                      wowDuration={"1.9s"}
                      wowDelay={".6s"}
                      moreInfo={false}
          />

          <FeatureBox title={"Analytics"}
                      description={"Con la información recolectada, genera reportes avanzados sobre el comportamiento de tus clientes, identifica a los mejores y ofreceles un trato personalizado."}
                      img={"images/icon/feature2.png"}
                      wowDuration={"1.9s"}
                      wowDelay={".6s"}
                      moreInfo={false}
          />

          <FeatureBox title={"Aplicación para tus clientes"}
                      description={"Inicia tu presencia digital y permite a tus clientes conocer tus promociones promociones, consultar sus cupones y más."}
                      img={"images/icon/feature4.png"}
                      wowDuration={"1.9s"}
                      wowDelay={".6s"}
                      moreInfo={false}
          />

          <FeatureBox title={"Ludopatía"}
                      description={"Con nuestro modulo de ludopatía evitas el registro de personas no habilitadas en tu club de jugadores. Crea tus propias listas. Evita multas."}
                      img={"images/icon/feature4.png"}
                      wowDuration={"1.9s"}
                      wowDelay={".6s"}
                      moreInfo={false}
          />

          <FeatureBox title={"y mucho mas..."}
                      description={"Pronto estaremos anunciando más novedades"}
                      img={"images/icon/feature1.png"}
                      wowDuration={"1.9s"}
                      wowDelay={".6s"}
                      moreInfo={false}
          />

        </div>
      </div>
    </section>
  )
}
