import React, { useEffect, useState } from "react"
import OwlCarousel from "react-owl-carousel"

const InnerText = () => {
  return (
    <div className="row justify-content-center text-center">

      <div className="col-md-10">
        {/*<h1 className="tw-slider-subtitle">Analizar Innovar Automatizar</h1>*/}
        <h4 className="tw-slider-title">Evoluciona tu
          <span> sala de juego</span>
        </h4>

        <a href="#demo" className="btn btn-primary">Solicita tu demo</a>
      </div>

    </div>
  )
}

export default function OwlCarouselMine() {

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  })

  return (

    <div>
      {loaded &&
      <OwlCarousel className="tw-hero-slider owl-carousel"
                   items={1}
                   loop
                   autoplay
                   nav
                   dots={false}
                   autoplayTimeout={8000}
                   autoplayHoverPause={true}
                   mouseDrag={false}
                   smartSpeed={1000}
                   margin={10}
                   navText={["<i class=\"icon icon-left-arrow2\">", "<i class=\"icon icon-right-arrow2\">"]}
      >

        <div className="slider-1 slider-map-pattern">
          <div className="slider-wrapper d-table">
            <div className="slider-inner d-table-cell">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <img src={"images/slider/slider1.png"} alt="slider1" className="slider-img img-fluid"/>
                  </div>
                </div>

                <InnerText/>
              </div>
            </div>
          </div>
        </div>


        <div className="slider-2">
          <div className="slider-arrow">
            <img src={"images/slider/pattern_arrow2.png"} alt="sliderArrow1"/>
            <img src={"images/slider/pattern_arrow1.png"} alt="sliderArrow2"/>
            <img src={"images/slider/pattern_arrow3.png"} alt="sliderArrow3"/>
          </div>
          <div className="slider-wrapper d-table">
            <div className="slider-inner d-table-cell">
              <div className="container">
                <div className="row justify-content-center">

                  <div className="col-md-6">
                    <div className="slider-content">
                      <h1>Las mejores soluciones
                        <span> para tu operación</span>
                      </h1>
                      {/*<p>Brindamos soluciones efectivas e innovadores con el uso de tecnología de vanguardia.*/}
                      {/*  Productos de alto impacto que transformaran tu negocio y lo volveran más*/}
                      {/*  rentable.</p>*/}
                      <p>
                        Contamos con el sistema de gestión de promociones y clientes mas completo e innovador hecho en
                        el Perú.
                        Unete a la revolución sin necesidad de grandes inversiones.
                      </p>
                      <a href="#demo" className="btn btn-dark">Solicita tu demo</a>

                    </div>
                  </div>

                  <div className="col-md-6">
                    <img src={"images/slider/slider2.png"} alt="" className="img-fluid slider-img"/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="slider-3">
          <div className="slider-arrow">
            <img src={"images/slider/pattern_arrow2.png"} alt="sliderArrow1"/>
            <img src={"images/slider/pattern_arrow1.png"} alt="sliderArrow2"/>
            <img src={"images/slider/pattern_arrow3.png"} alt="sliderArrow3"/>
          </div>
          <div className="slider-wrapper d-table">
            <div className="slider-inner d-table-cell">
              <div className="container">
                <div className="row justify-content-center">

                  <div className="col-md-6">
                    <img src={"images/slider/slider3.png"} alt="" className="img-fluid slider-img"/>
                  </div>

                  <div className="col-md-6">
                    <div className="slider-content">
                      <h1>Tu éxito,
                        <span> nuestra META</span>
                      </h1>
                      {/*<p>Manejamos una metodología que está orientada a resultados, te brindamos la*/}
                      {/*  información necesaria en el momento oportuno apoyándonos en el análisis de*/}
                      {/*  datos.</p>*/}
                      <p>
                        Ponemos a tu alcance informacion valiosa sobre tu sala,
                        para que puedas tomar mejores decisiones y aumentar tu producción.
                      </p>

                      <a href="#demo" className="btn btn-dark">Solicita tu demo</a>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </OwlCarousel>
      }
    </div>
  )
}
