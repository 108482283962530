import React, { useEffect, useState } from "react"
import Header from "../components/Header"
import OwlCarouselMine from "../components/OwlCarouselMine"
import FeaturesArea from "../components/FeaturesArea"
import WargosAboutUs from "../components/WargosAboutUs"
import WillContactYou from "../components/WillContactYou"
import Footer from "../components/Footer"
import Clients from "../components/Clients"
import Facts from "../components/Facts"
import ContactForm from "../components/ContactForm"
import Helmet from "react-helmet"

export default function Home() {

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  })

  // useEffect(() => {
  //   $("h1").css("color", "red")
  //
  //   console.log("iniciando carosul")
  //   /*Main Slideshow*/
  //   $(".tw-hero-slider").owlCarousel({
  //     items: 1,
  //     loop: true,
  //     autoplay: true,
  //     nav: true,
  //     dots: false,
  //     autoplayTimeout: 8000,
  //     autoplayHoverPause: true,
  //     mouseDrag: false,
  //     smartSpeed: 1100,
  //     navText: ['<i class="icon icon-left-arrow2">', '<i class="icon icon-right-arrow2">'],
  //   });
  //
  //   /* Client carousel */
  //   $(".clients-carousel").owlCarousel({
  //     items: 4,
  //     loop: true,
  //     nav: false,
  //     dots: true,
  //     autoplay: true,
  //     responsiveClass: true,
  //     autoplayHoverPause: true,
  //     mouseDrag: false,
  //     smartSpeed: 900,
  //     responsive: {
  //       0: {
  //         items: 1,
  //       },
  //       600: {
  //         items: 2,
  //       },
  //       1000: {
  //         items: 4,
  //       }
  //     }
  //   });
  //
  //   /* Counter UP */
  //   $(".counter").counterUp({
  //     delay: 10,
  //     time: 2000
  //   });
  //
  //
  //
  //
  // }, [])

  return <div>

    <Helmet>
      <meta charSet="utf-8" />
      <title>WGT | Evoluciona tu sala de juego</title>
      <meta name="description" content="Sistemas de cupones, promociones, ludopatia y mas"/>
    </Helmet>

    <Header/>

    <OwlCarouselMine/>

    <FeaturesArea/>
    <WillContactYou/>
    <WargosAboutUs/>

    <Facts/>
    <Clients/>

    <div id="demo">
      <ContactForm/>
    </div>

    <Footer/>

  </div>
}
