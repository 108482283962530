import React from "react"

const Fact = ({ title, count, image }) => {
  return (
    <div className="tw-facts-box">
      <div className="facts-img wow zoomIn" data-wow-duration="1s">
        <img src={image} alt="" className="img-fluid"/>
      </div>
      <div className="facts-content wow fadeInUp" data-wow-duration="1s">
        <h4 className="facts-title">{title}</h4>
        <span className="counter">{count}</span>
        <sup>+</sup>
      </div>
    </div>
  )
}

const Fact2 = ({ title, count, image }) => {
  return (
    <div className="tw-facts-box">
      <div className="facts-img wow zoomIn">
        <img src={image} alt="" className="img-fluid"/>
      </div>
      <div className="facts-content wow slideInUp">
        <h4 className="facts-title">{title}</h4>
        <span className="counter">{count}</span>
        <sup>+</sup>
      </div>
    </div>
  )
}

export default function Facts() {
  return (
    <section id="tw-facts" className="tw-facts">
      <div className="facts-bg-pattern d-none d-lg-block">
        <img className="wow fadeInLeft" src="images/funfacts/arrow_left.png" alt="arrwo_left"/>
        <img className="wow fadeInRight" src="images/funfacts/arrow_right.png" alt="arrow_right"/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 text-center">
            <Fact title={"Salas satisfechas"}
                  count={40}
                  image={"images/icon/fact1.png"}
            />
          </div>

          <div className="col-md-4 text-center">
            <Fact2 title={"Experiencia en la industria"}
                   count={10}
                   image={"images/icon/fact2.png"}
            />

          </div>

          <div className="col-md-4 text-center">
            <Fact2 title={"Soporte"}
                   count={365}
                   image={"images/icon/fact5.png"}
            />
          </div>

          {/*<div className="col-md-3 text-center">*/}
          {/*  <Fact2 title={"Proyectos exitosos"}*/}
          {/*         count={35}*/}
          {/*         image={"images/icon/fact4.png"}*/}
          {/*  />*/}
          {/*</div>*/}

        </div>
      </div>
    </section>
  )
}
