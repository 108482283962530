import React from "react"


export default function WargosAboutUs() {
  return (

    <section id="tw-analysis" className="tw-analysis-area">
      <div className="analysis-bg-pattern d-none d-md-inline-block">
        <img className="wow fadeInUp" src="images/check-seo/money.png" alt=""/>
        <img className="wow fadeInUp" src="images/check-seo/mach.png" alt=""/>
        <img className="wow fadeInUp" src="images/check-seo/money2.png" alt=""/>
        <img className="wow fadeInUp" src="images/check-seo/token.png" alt=""/>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center wow fadeInDown">
            <h2 className="column-title">
              Empieza ya,
              <span className="text-white" style={{paddingLeft: '8px'}}>sin servidores, sin equipos costosos.</span>
            </h2>
            <div className="analysis-form">
              {/*<form className="form-vertical">*/}
              {/*  <div className="row justify-content-center">*/}
              {/*    <div className="col-lg-8 col-md-12 no-padding">*/}
              {/*      <div className="form-group tw-form-round-shape">*/}
              {/*        <input type="email" id="email" name="email" placeholder="tuempresa@gmail.com"*/}
              {/*               className="form-control"/>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-lg-4 col-md-12 no-padding">*/}
              {/*      <div className="form-group">*/}
              {/*        <input type="submit" value="Contáctame"/>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</form>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
