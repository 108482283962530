import React from "react"


export default function WargosAboutUs() {

  return (
    <section id="tw-intro" className="tw-intro-area">
      <div className="tw-ellipse-pattern">
        <img src="images/about/about_ellipse.png" alt=""/>
      </div>
      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-md-12 text-lg-right text-md-center wow fadeInLeft" data-wow-duration="1s">
            <img src="images/about/about_image.png" alt="" className="img-fluid"/>
          </div>
          <div className="col-lg-5 ml-auto col-md-12 wow fadeInRight" data-wow-duration="1s">
            <h2 className="column-title">WARGOS TECHNOLOGIES</h2>
            <span className="animate-border tw-mb-40"></span>
            <p>
              Somos una startup peruana innovadora y de vanguardia que usa las mejores tecnologias para crear herramientas
              que rentabilicen tu sala de juego.
            </p>
            <p>
              Parte de nuestra filosofía es hacer un uso inteligente de recursos, automatizar procesos, y
              maximizar la eficiencia.
            </p>
            <a href="#" className="btn btn-primary">Conoce más</a>
            <a href="#" className="btn btn-secondary">Contáctanos</a>
          </div>
        </div>
      </div>
    </section>
  )
}
