import React, { useEffect, useState } from "react"
import OwlCarousel from "react-owl-carousel"

export default function Clients() {

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  })


  return (
    <section id="tw-client" className="tw-client">
      <div className="container">
        <div className="row  wow fadeInUp">
          <div className="col-md-12">


            {loaded &&

            <OwlCarousel className="clients-carousel owl-carousel"
                         items={4}
                         loop
                         autoplay
                         nav={false}
                         dots={true}
                         autoplayTimeout={8000}
                         autoplayHoverPause={true}
                         mouseDrag={false}
                         smartSpeed={900}
                         margin={10}
                         navText={["<i class=\"icon icon-left-arrow2\">", "<i class=\"icon icon-right-arrow2\">"]}
                         responsive={
                                  {
                                   0: {
                                     items: 1,
                                   },
                                   600: {
                                     items: 2,
                                   },
                                   1000: {
                                     items: 4,
                                   }
                                 }
                         }
            >

              <div className="client-logo-wrapper d-table">
                <div className="client-logo d-table-cell">
                  <img src="images/clients/client1.jpg" alt=""/>
                </div>
              </div>
              <div className="client-logo-wrapper d-table">
                <div className="client-logo d-table-cell">
                  <img src="images/clients/client2.jpg" alt=""/>
                </div>
              </div>
              <div className="client-logo-wrapper d-table">
                <div className="client-logo d-table-cell">
                  <img src="images/clients/client3.jpg" alt=""/>
                </div>
              </div>
              <div className="client-logo-wrapper d-table">
                <div className="client-logo d-table-cell">
                  <img src="images/clients/client4.jpg" alt=""/>
                </div>
              </div>
              <div className="client-logo-wrapper d-table">
                <div className="client-logo d-table-cell">
                  <img src="images/clients/client5.jpg" alt=""/>
                </div>
              </div>

              {/*<div className="client-logo-wrapper d-table">*/}
              {/*  <div className="client-logo d-table-cell">*/}
              {/*    <img src="images/clients/client6.jpg" alt=""/>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="client-logo-wrapper d-table">
                <div className="client-logo d-table-cell">
                  <img src="images/clients/client7.jpg" alt=""/>
                </div>
              </div>
              <div className="client-logo-wrapper d-table">
                <div className="client-logo d-table-cell">
                  <img src="images/clients/client8.jpg" alt=""/>
                </div>
              </div>
              <div className="client-logo-wrapper d-table">
                <div className="client-logo d-table-cell">
                  <img src="images/clients/client9.jpg" alt=""/>
                </div>
              </div>
              <div className="client-logo-wrapper d-table">
                <div className="client-logo d-table-cell">
                  <img src="images/clients/client10.jpg" alt=""/>
                </div>
              </div>


            </OwlCarousel>
            }



          </div>
        </div>
      </div>
    </section>
  )
}
